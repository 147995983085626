<template>
    <div class="modal profile_modal schedule" v-if="modelValue" @click.self="closeModel()">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <h1>Scheduled SMS</h1>
                <button class="close_btn" @click="closeModel()"><i class="fas fa-times"></i></button>
            </div>
            <div class="modal_body">
                <div class="result_wpr">
                    <div class="actions">
                        <ul>
                            <li class="edit">
                                <label for="check" class="checkbox">
                                    <input type="checkbox" id="check" v-model="selectAll" :true-value="1" :false-value="0" @change="toggleSelectAll" hidden>
                                    <span><i class="fas fa-check"></i></span>
                                </label>
                            </li>
                            <li class="optionDrops sort_list" @click="togglePerPageFilter()" v-click-outside="closePageFilter">
                                {{ params.per_page }} <i  class="fas fa-angle-down"></i>
                                <page-filter v-model="params.per_page" :type="2" :is-dropdown="true" ref="per-page-filter" />
                            </li>
                            <li><input type="text" class="p-0" @input="isTyping = true" v-model.trim="params.search" placeholder="Search" />
                                <i class="fas fa-search"></i>
                            </li>
                            <li class="delete"  @click="handleDeleteScheduledSms()"><i class="fas fa-trash-alt"></i></li>
                            <li class="list_info">{{ scheduledSms.from ? scheduledSms.from : 0 }} - {{ scheduledSms.to ? scheduledSms.to : 0 }} of <span>{{ scheduledSms.total }}</span></li>
                        </ul>
                    </div>
                    <div v-if="loader"><line-loader /></div>
                    <table class="standard">
                        <thead>
                            <tr>
                                <th></th>
                                <th>SMS</th>
                                <th>Media</th>
                                <th>Timezone</th>
                                <th>Schedule</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody v-if="scheduledSms.total">
                            <tr v-for="(schedule, s) in scheduledSms.data" :key="s">
                                <td>
                                    <label :for="`contact-${s}`" class="checkbox">
                                        <input type="checkbox" :id="`contact-${s}`" :value="schedule.id" v-model="params.schedule_ids" hidden>
                                        <span><i class="fas fa-check"></i></span>
                                    </label>
                                </td>
                                <td>{{ schedule.sms }}</td>
                                <td v-if="schedule.media">
                                    <div class="schedule_media" v-html="getFileIconByUrl(schedule.media, uniqueId, '' )"></div>
                                </td>
                                <td v-else>{{ '-'}}</td>
                                <td>{{ schedule.time_zone }}</td>
                                <td>{{ moment(schedule.schedule).format('ll | LT') }}</td>
                                <td>
                                    <button class="success" v-if="schedule.in_queue == 1 && schedule.expired_time_in_minutes < 15">Sending</button>
                                    <button class="failed" v-if="schedule.in_queue == 2 || (schedule.in_queue == 1 && schedule.expired_time_in_minutes > 15)">Failed</button>
                                    <button v-else>Waiting</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="pagination pb-4 mt-4"  v-if="scheduledSms.total">
                    <pagination :range-size="0" v-model="params.page" :pages="scheduledSms.last_page" @update:modelValue="handlePaginationSms" />
                </div>
            </div>
            <div class="modal_footer">
                <button type="button" class="btn cancel_btn" @click="closeModel()">Cancel</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    import { uuid } from 'vue-uuid';

    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'
    import moment from 'moment'

    export default {
        name: 'Scheduled Sms',

        data () {
            return {
                params: {
                    page: 1,
                    per_page: 5,
                    search: '',
                    schedule_ids: [],
                    contact_id: '',
                },
                moment,
                isTyping: false,
                selectAll: 0,
                uniqueId: 'id'+uuid.v4(),
            }
        },

        props: {
            modelValue: Boolean,
            contactId: [Number, String]
        },

        emit: ['update:modelValue'],

        watch: {
            modelValue (value) {
                const vm = this;

                if (value) {
                    vm.resetForm();
                }
            },

            'params.per_page' () {
                const vm = this;
                vm.params.page = 1;

                vm.getScheduledSMS(vm.params);
            },

            'params.search' () {
                const vm = this;

                setTimeout(() => {
                    vm.isTyping = false;
                }, 1500);
            },

            isTyping (val) {
                const vm = this;

                if (!val) {
                    if (vm.params.search != null) {
                        if (vm.params.search.length >= 2 || vm.params.search.length === 0) {
                            vm.params.page = 1;
                            vm.getScheduledSMS(vm.params);
                        }
                    }
                }
            },

            'params.schedule_ids' (ids) {
                const vm = this;

                if (vm.scheduledSms.data && Object.keys(vm.scheduledSms.data).length !== ids.length) {
                    vm.selectAll = 0;
                } else if (vm.scheduledSms.data) {
                    vm.selectAll = 1;
                }
            },
        },

        computed: mapState ({
            scheduledSms: state => state.smsCenterModule.scheduledSms,
            loader: state => state.smsCenterModule.scheduledSmsLoader,
        }),

        methods: {
            ...mapActions ({
                getScheduledSMS: 'smsCenterModule/getScheduledSMS',
                deleteScheduledSms: 'smsCenterModule/deleteScheduledSms',
            }),

            closeModel () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            togglePerPageFilter (value) {
                const vm = this;
                const filter = vm.$refs['per-page-filter'];

                if (filter) {
                    filter.dropdown = !filter.dropdown;
                }
            },

            closePageFilter () {
                const vm = this;
                const filter = vm.$refs['per-page-filter'];

                if (filter) {
                    filter.dropdown = false;
                }
            },

            resetForm () {
                const vm = this;

                vm.params = {
                    page: 1,
                    per_page: 5,
                    search: '',
                    schedule_ids: [],
                    contact_id: vm.contactId,
                },

                vm.getScheduledSMS(vm.params);
            },

            handlePaginationSms (page) {
                const vm = this;

                vm.params.page = page;
                vm.getScheduledSMS(vm.params);
            },

            handleDeleteScheduledSms () {
                const vm = this;

                if (vm.params.schedule_ids.length !== 0) {
                    const option = Helper.swalConfirmOptions('Are You Sure?', "You will not be able to recover these scheduled sms.", 'Yes')
                    const params = Object.assign({}, vm.params);
                    option.preConfirm = function() {
                                            const scheduleIds = vm.params.schedule_ids;

                                            return vm.deleteScheduledSms(scheduleIds).then((result) => {
                                                        vm.params.page         = 1;
                                                        vm.params.search       = '';
                                                        vm.params.schedule_ids = [];
                                                        vm.selectAll           = 0;

                                                        vm.getScheduledSMS(vm.params);
                                                    });
                                        };

                    Swal.fire(option);
                } else {
                    const option = Helper.swalWarningOptions('Alert!', 'Please select at least one in the scheduled sms!', 'Okay')

                    Swal.fire(option);
                }
            },

            toggleSelectAll () {
                const vm = this;

                if (vm.selectAll) {
                    vm.scheduledSms.data.forEach((result) => {
                        vm.params.schedule_ids.push(result.id);
                    });
                } else {
                    vm.params.schedule_ids = [];
                }
            },

            getFileIconByUrl (image, uniqueId, contentType = '') {
                let file = JSON.parse(image)
                if (file.length) {
                    return Helper.getFileIconByUrl(file[0], uniqueId, contentType);
                }
            },
        }
    }
</script>

<style scoped>
    .modal.schedule .modal_container {
        max-height: 640px;
        height: calc(100% - 30px);
        margin: 15px;
    }
    .modal.profile_modal > .modal_container > .modal_body{
        padding: 15px 30px 30px 30px;
    }

    .modal.profile_modal.schedule .modal_header {
        padding: 20px 30px 0;
    }

    .modal.profile_modal.schedule > .modal_container > .modal_body {
        padding: 15px 30px;
        flex-direction: column;
    }

    .modal_footer {
        border-radius: 0 0 12px 12px;
    }

    .actions>ul li.delete {
        flex: 0 0 50px;
        cursor: pointer;
    }

    .actions>ul li.delete i {
        color: #f04438;
        margin: 0;
    }

    .actions>ul li .dropdown_wpr {
        max-height: 300px;
        overflow-y: scroll;
    }

    .actions>ul li .dropdown_wpr::-webkit-scrollbar {
        width: 4px;
    }

    .actions>ul li .dropdown_wpr:hover::-webkit-scrollbar-thumb {
        background-color: #c7c7c7;
        border-radius: 2px;
    }

    table.standard th {
        font-size: 13px;
        line-height: 16px;
        padding: 15px 12px;
    }

    table.standard td {
        font-size: 13px;
        line-height: 16px;
        padding: 18px 12px;
    }

    table.standard td .user_wpr h4 {
        font-size: 13px;
        line-height: 16px;
        color: #121525;
        font-weight: 400;
        letter-spacing: 0;
    }

    table button {
        padding: 3px 10px;
        font-size: 11px;
        line-height: 13px;
        font-weight: 400;
        background: #f2a31e;
        border-radius: 10px;
        color: #fff;
        margin-left: 7px;
    }

    table button.success {
        background: #00aa14;
    }

    table button.failed {
        background: #f04438;
    }

    .schedule_media {
        width: 200px !important;
    }
</style>
